

















import { Emit, Component, Vue, Prop, PropSync } from 'vue-property-decorator'
// import DiaryEditor from './components/DiaryEditor.vue'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {},
})
export default class InjuredMark extends Vue {
  @Prop({ default: () => [] }) private tagInfo!: Array<any>
  @Prop({ default: 0 }) private readonly width!: Number
  @Prop({ default: 0 }) private readonly height!: Number
  @Prop({ default: '' }) private readonly type!: String

  private descriptionTemp: Array<string> = []

  private get isPc(): any {
    return this.$store.state.isPc
  }

  private created(): void {
    this.$nextTick(() => {
      this.initialData()
    })
  }

  private initialData(): void {
    this.descriptionTemp = this.tagInfo.map(item => item.description)
  }
}
